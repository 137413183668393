import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { addDays } from 'date-fns'
import { useGetApi } from '@/api/hooks'
import { createDocumentAssignment, getDealAgreementFormStore } from '@/components/composed/forms/DealAgreementForm/reduce'
import { openModal } from '@/components/composed/Modal/actions'
import PreviewDocument from '@/components/composed/PreviewDocument/PreviewDocument'
import { BubblingTooltip, Button, ErrorMessage, InjectHtml, Loader, TextWithIcon } from '@/components/ui'
import { Checkbox, DatePicker } from '@/components/ui/fields'
import SmsCodeTimer from '@/components/ui/SmsCodeTimer/SmsCodeTimer'
import { SERVER_DATE_FORMAT } from '@/constants/common'
import { useData } from '@/logic/data'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { EProfileRequestType } from '@/logic/profile/constants'
import { getRequestState } from '@/logic/requestStatus/reducer'
import { getOperationNextSendingTimestamp } from '@/logic/smsCode/reducer'
import { RootState } from '@/redux/interfaces'
import { ELegalDocumentType, TLegalDocuments } from '@/types/legalDocuments'
import { EAgrarianOrderStatus } from '@/types/Order'
import { downloadFileFromResponseBlob } from '@/utils/files'
import { getLinkOnDocument } from '@/utils/getLinkOnDocument'
import { useFormatDate } from '@/utils/hooks/format/useFormatDate'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { useDownloadFile } from '@/utils/hooks/useDowloadFile'
import { datePickerDisableDateOffer } from '@/utils/offer/datePickerDisableDateOffer'
import { DAYS_TO_ACCEPT_REQUEST_OFFER, STYLE_FOR_OFFER } from '../constants'
import { EDealAgreementStatus, IDealAgreementContent, TDealAgreementOperationName, TDealAgreementStepProps } from '../interfaces'
import { IEditingDisagreementProtocolModalOptions } from './EditingDisagreementProtocolModal/interfaces'

const mapState = (sendingCodeRequestName: string, operationName: TDealAgreementOperationName) => (state: RootState) => {
  return {
    requestState: getRequestState(sendingCodeRequestName)(state),
    operationNextSendingTimestamp: getOperationNextSendingTimestamp(operationName)(state),
    dealAgreementFormState: getDealAgreementFormStore()(state),
  }
}

const AgreementFirstStep: TDealAgreementStepProps = props => {
  const {
    values,
    showNextForm,
    sendingCodeRequestName,
    contentUrl,
    urlToDownload,
    sendSmsCode,
    disagreementProtocol,
    saveDisagreementProtocol,
    goBack,
    accreditationId,
    version,
    disagreementButtonText,
    operationName,
    form,
    entityType,
    paymentType,
    orderId,
    orderStatusId,
  } = props
  const { data, isLoading: isDealAgreementPayloadLoading, meta } = useGetApi<IDealAgreementContent>(contentUrl, [version])
  const { data: documents = [] } = useData<TLegalDocuments>('legalDocuments')
  const { response, responseBlob, size, unit, isLoading, isFailure: isFailureFile } = useDownloadFile<Blob>(urlToDownload, [version])
  const { value = '', validToRequired } = data || {}
  const { requestState, operationNextSendingTimestamp, dealAgreementFormState } = useSelector(
    mapState(sendingCodeRequestName, operationName),
  )

  const isMobile = useDeviceType()

  const dispatch = useDispatch()
  const { isFailure, isLoaded: isSmsSent, isLoading: isSmsCodeSending } = requestState
  const { formatDate } = useFormatDate()

  const isOrderType = entityType === EProfileRequestType.ORDERS

  const isDatePickerNeeded = isOrderType
  const isAssignmentType = isOrderType && orderStatusId === EAgrarianOrderStatus.AGRARIAN_ASSIGNMENT_WAITING

  useEffect(() => {
    const fetchDocument = async () => {
      if (orderId) {
        await dispatch(createDocumentAssignment({ orderId }))
      }
    }

    if (isAssignmentType) {
      fetchDocument()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId])

  useEffect(() => {
    if (isSmsSent) showNextForm()
    //eslint-disable-next-line
  }, [isSmsSent])

  useEffect(() => {
    if (validToRequired && !isDatePickerNeeded) {
      form.change('validTo', formatDate(addDays(new Date(), DAYS_TO_ACCEPT_REQUEST_OFFER), SERVER_DATE_FORMAT))
    }
    // eslint-disable-next-line
  }, [validToRequired])

  const { validTo, isAgreed } = values
  const openEditingDisagreementProtocolModal = () =>
    dispatch(
      openModal({
        options: {
          dialogId: 'EditingDisagreementProtocolModal',
        },
        contentOptions: {
          disagreementProtocol,
          goBack,
          saveDisagreementProtocol,
          accreditationId,
          version,
        } as IEditingDisagreementProtocolModalOptions,
      }),
    )

  const htmlUrl: string = useMemo(
    () => URL.createObjectURL(new Blob([`${value}<style>${STYLE_FOR_OFFER}</style>`], { type: 'text/html' })),
    [value],
  )

  const downloadDocument = () => {
    if (response && responseBlob) {
      const tab = window.open('', '_blank')
      downloadFileFromResponseBlob(response, responseBlob, 'Doc.pdf', tab)
    }
  }

  const shownOrganizationDetailsRequired = meta?.status === EDealAgreementStatus.ORGANIZATION_DETAILS_REQUIRED

  return (
    <>
      <Loader isVisible={isDealAgreementPayloadLoading} />

      {shownOrganizationDetailsRequired ? (
        <div className="dealAgreementModalRequired">
          <InjectHtml html={meta?.message || ''} className="dealAgreementModalRequired__content" />
          <CommonLink url="/profile/organizationData" className="button button_green">
            <FormattedMessage id="dealAgreementModal.profile.button" />
          </CommonLink>
        </div>
      ) : (
        <>
          <h3 className="dealAgreementModal__title">
            <FormattedMessage id={`dealAgreementModal.${operationName}.title`} />
          </h3>

          <div className={classNames('dealAgreementModalWrapper', { withDatePicker: validToRequired && isDatePickerNeeded })}>
            <div className="dealAgreementModalContent">
              <PreviewDocument
                className="dealAgreementModalContent__document"
                documentData={isAssignmentType ? dealAgreementFormState.document : htmlUrl}
                documentContentType={isAssignmentType ? 'base64' : 'html'}
              />
            </div>
            <div className="dealAgreementModalItem">
              <div
                className={classNames('dealAgreementModalItem__info', {
                  dealAgreementModalItem__infoFail: isFailureFile,
                })}
              >
                <span>
                  <BubblingTooltip messageId={`dealAgreementModal.text.mobileDocument.${isFailureFile ? 'fail' : operationName}`}>
                    <span>
                      <FormattedMessage id={`dealAgreementModal.text.mobileDocument.${isFailureFile ? 'fail' : operationName}`} />
                    </span>
                  </BubblingTooltip>
                </span>
                {!isFailureFile && (
                  <>
                    <div />
                    <span className="dealAgreementModalItem__infoDescription">
                      PDF
                      {size && unit && (
                        <>
                          &nbsp;
                          {size}
                          &nbsp;
                          <FormattedMessage id={`dealAgreementModal.unit.${unit?.toLowerCase()}`} />
                        </>
                      )}
                    </span>
                  </>
                )}
              </div>
              {isAssignmentType ? (
                <CommonLink
                  className={classNames('dealAgreementModal__downloadButton button button_outline button_full-width')}
                  url={getLinkOnDocument(dealAgreementFormState.document)}
                  isDocument
                  target="_blank"
                >
                  <FormattedMessage id={`dealAgreementModal.${operationName}.downloadDocument`} />
                </CommonLink>
              ) : (
                <Button
                  className={classNames('dealAgreementModal__downloadButton')}
                  modifiers={[isMobile ? 'green' : 'outline']}
                  onClick={downloadDocument}
                  loaderProps={{ isVisible: isLoading }}
                  isDisabled={isFailureFile}
                >
                  <FormattedMessage
                    id={isMobile ? 'dealAgreementModal.text.openDocument' : `dealAgreementModal.${operationName}.downloadDocument`}
                  />
                </Button>
              )}
            </div>
            {validToRequired && isDatePickerNeeded && (
              <DatePicker
                name="validTo"
                placeholderId="dealAgreementModal.validTo.placeholder"
                parsingFormat={SERVER_DATE_FORMAT}
                className="dealAgreementModal__datePicker"
                pickerProps={{ disabledDate: datePickerDisableDateOffer(paymentType) }}
              />
            )}
          </div>

          <Checkbox
            controlStyle={EColor.WHITE}
            borderColorStyle={EColor.GRAY}
            activeHoverBackgroundStyle={EColor.DARK_GREEN}
            hoverBackgroundStyle={EColor.LIGHT_GREEN}
            hoverControlStyle={EColor.BLACK}
            name="isAgreed"
            id="isAgreed"
            className="dealAgreementModal__agreed"
          >
            <div>
              <FormattedMessage
                id={`dealAgreementModal.${operationName}.agreementCheckbox.label`}
                values={{
                  registrationRulesLink: (
                    <CommonLink
                      shouldOpenInNewWindow
                      url={getLegalDocumentUrl(documents, ELegalDocumentType.registrationRules)}
                      className="underline underline_green color_green"
                    >
                      <FormattedMessage id="legalDocumentsPage.userRegistrationRules.text" />
                    </CommonLink>
                  ),
                }}
              />
            </div>
          </Checkbox>

          <ErrorMessage isVisible={isFailure} textId="dealAgreementModal.sendingSmsCode.error" className="text_center" />
          <SmsCodeTimer
            textId="dealAgreementModal.timeUntilDisplayingSendCodeButton.text"
            className="text_center"
            operationName={operationName}
          />
          <div className="dealAgreementModal__bottomLine row">
            <div className="col-12">
              <Button
                onClick={() => sendSmsCode(values)}
                modifiers={['green', 'full-width']}
                textId={`dealAgreementModal.${operationName}.sendingCodeButton`}
                className="dealAgreementModal__button dealAgreementModal__buttonAccepting"
                isDisabled={!isAgreed || (validToRequired && !validTo) || Boolean(operationNextSendingTimestamp)}
                loaderProps={{ isVisible: isSmsCodeSending }}
              />
            </div>
            {disagreementButtonText && (
              <div className="dealAgreementModal__disagreement col-12">
                <Button
                  onClick={openEditingDisagreementProtocolModal}
                  modifiers={['outline', 'full-width']}
                  className="deal-agreement-modal__button"
                  loaderProps={{ isVisible: isSmsCodeSending }}
                >
                  {disagreementButtonText}
                </Button>
              </div>
            )}
          </div>
          <TextWithIcon
            iconProps={{ name: EIconName.InfoFill, style: { color: EColor.LIGHT_GRAY } }}
            className="dealAgreementModal__notice"
            textId={`dealAgreementModal.${operationName}.notice`}
          />
        </>
      )}
    </>
  )
}

export default AgreementFirstStep
