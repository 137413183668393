import React, { Children, ReactElement } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import isEqual from 'lodash/isEqual'
import { scrollToTop } from '@/utils/scroll'
import { IWizardFormProps, IWizardFormState, IWizardPageComponentProps, TWizardPageProps } from './interfaces'

/*eslint-disable @typescript-eslint/no-unused-vars*/
export const WizardFormPage = function <Props, Values>(_props: TWizardPageProps<Values> & Props) {
  return null
}

class WizardForm<Values = Record<string, any>> extends React.PureComponent<IWizardFormProps<Values>, IWizardFormState<Values>> {
  constructor(props: IWizardFormProps<Values>) {
    super(props)

    this.state = {
      pageNumber: props.initialPageNumber || 0,
      values: props.initialValues || {},
      AutoSave: props.AutoSave,
      autoFocus: props.autoFocus,
      forceScrollToTop: props.forceScrollToTop,
    }
  }

  componentDidMount() {
    if (this.state.autoFocus) {
      const firstInput = document.querySelector(`.${this.formClassName} input:not([disabled]`) as HTMLElement

      firstInput?.focus()
    }

    if (this.state.forceScrollToTop) scrollToTop()
  }

  componentDidUpdate(prevProps: IWizardFormProps<Values>) {
    const { initialValues } = this.props

    if (prevProps.initialPageNumber !== this.state.pageNumber) {
      this.props.getCurrentPageNumber?.(this.state.pageNumber)
    }

    if (!isEqual(prevProps.initialValues, initialValues)) {
      this.setState({ values: initialValues || {} })
    }
  }

  onSubmit = (values: Partial<Values>) => {
    const { onSubmit, shouldSwitchPageOnSubmit = true } = this.props
    if (this.state.pageNumber === this.totalPagesQuantity - 1) onSubmit(values as Values)

    if (shouldSwitchPageOnSubmit) this.showNextPage(values)
  }

  get ActivePageComponent() {
    return this.activePage.props.Component as React.FC<IWizardPageComponentProps>
  }

  get activePage() {
    return Children.toArray(this.props.children)[this.state.pageNumber] as ReactElement
  }

  get totalPagesQuantity() {
    return Children.toArray(this.props.children).filter(Boolean).length
  }

  get formClassName() {
    return `wizard-form_${this.state.pageNumber + 1}`
  }

  showNextPage = (values: Partial<Values>) =>
    this.setState(({ pageNumber }) => ({
      pageNumber: Math.min(pageNumber + 1, this.totalPagesQuantity - 1),
      values,
    }))

  showPreviousForm = () => this.setState(({ pageNumber }) => ({ pageNumber: Math.max(pageNumber - 1, 0) }))

  goToForm = (pageNumber: number) => this.setState({ pageNumber })

  validate = (values: Partial<Values>): Partial<Record<keyof typeof values, string>> => {
    const { validate } = this.activePage.props

    return validate ? validate(values) : {}
  }

  render() {
    const { pageNumber, values, AutoSave } = this.state
    const ActivePageComponent = this.ActivePageComponent
    const isFirstPage: boolean = pageNumber === 0
    const totalPagesQuantity: number = this.totalPagesQuantity
    const isLastPage: boolean = pageNumber === totalPagesQuantity - 1
    const { formRef } = this.props

    return (
      <Form<Values> initialValues={values} validate={this.validate} onSubmit={this.onSubmit}>
        {(formProps: FormRenderProps<Values>) => {
          if (formRef) {
            // @ts-ignore
            formRef.current = { ...formProps, pageNumber }
          }

          return (
            <form onSubmit={formProps.handleSubmit} className={`wizard-form ${this.formClassName}`}>
              <ActivePageComponent
                key={pageNumber}
                {...formProps}
                {...this.activePage.props}
                isFirstPage={isFirstPage}
                isLastPage={isLastPage}
                pageNumber={pageNumber}
                goToForm={this.goToForm}
                showPreviousForm={this.showPreviousForm}
                showNextForm={() => this.showNextPage(formProps.values as Partial<Values>)}
                totalPagesQuantity={totalPagesQuantity}
              />
              {AutoSave && <AutoSave {...formProps} />}
            </form>
          )
        }}
      </Form>
    )
  }
}

export default WizardForm
