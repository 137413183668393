import React from 'react'
import { useDispatch } from 'react-redux'
import { setInitialStatus } from '@/logic/requestStatus/actions'
import { injectMessages } from '@/logic/translation/utils'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { useMount } from '@/utils/hooks'
import { addModalToWellKnown } from '../../Modal/constants'
import { WizardFormPage } from '../WizardForm/WizardForm'
import { WizardForm } from '..'
import { AgreementFirstStep, AgreementSecondStep, EditingDisagreementProtocolModal } from './components'
import asyncEpics from './epics'
import { IDealAgreementFormProps, IDealAgreementFormValues, IDealAgreementStepProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

addNewEpicsLazily([asyncEpics])
addModalToWellKnown({ EditingDisagreementProtocolModal })
injectMessages(messages)

const DealAgreementForm: React.FC<IDealAgreementFormProps> = ({ onSubmit, stepProps }) => {
  const { sendingCodeRequestName, signingDealRequestName } = stepProps
  const dispatch = useDispatch()
  useMount(undefined, () => {
    dispatch(setInitialStatus({ name: signingDealRequestName }))
    dispatch(setInitialStatus({ name: sendingCodeRequestName }))
  })

  return (
    <WizardForm<IDealAgreementFormValues> onSubmit={onSubmit} shouldSwitchPageOnSubmit={false}>
      <WizardFormPage<IDealAgreementStepProps, IDealAgreementFormValues> Component={AgreementFirstStep} {...stepProps} />
      <WizardFormPage<IDealAgreementStepProps, IDealAgreementFormValues> Component={AgreementSecondStep} {...stepProps} />
    </WizardForm>
  )
}

export default DealAgreementForm
