import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { allowSendingCode } from '@/logic/smsCode/actions'
import { getOperationNextSendingTimestamp } from '@/logic/smsCode/reducer'
import { RootState } from '@/redux/interfaces'
import { ITimerProps } from '../Timer/interfaces'
import Timer from '../Timer/Timer'

const mapState = (operationName: string | undefined) => (state: RootState) => ({
  operationNextSendingTimestamp: getOperationNextSendingTimestamp(operationName)(state),
})

const SmsCodeTimer: React.FC<Omit<ITimerProps, 'defaultTimestamp'>> = props => {
  const { operationNextSendingTimestamp } = useSelector(mapState(props.operationName))
  const dispatch = useDispatch()

  if (!operationNextSendingTimestamp) return null

  const onComplete = () => {
    dispatch(allowSendingCode(props.operationName))
    props.onComplete?.()
  }

  return <Timer {...props} defaultTimestamp={operationNextSendingTimestamp} onComplete={onComplete} />
}

export default SmsCodeTimer
