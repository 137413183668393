import React, { ReactElement, useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { WizardForm } from '@/components/composed/forms'
import { WizardFormPage } from '@/components/composed/forms/WizardForm'
import { BubblingTooltip, Button, ErrorMessage, InfoText, PaginationDots } from '@/components/ui'
import {
  Checkbox,
  DatePicker,
  LoanPurposeSelect,
  PolicyAgreement,
  RegionSelect,
  SearchCitiesWithSuggestions,
  TextInput,
} from '@/components/ui/fields'
import { setInitialStatus } from '@/logic/requestStatus/actions'
import { getRequestMeta, getRequestState } from '@/logic/requestStatus/reducer'
import { RootState } from '@/redux/interfaces'
import { useMount } from '@/utils/hooks'
import { ICustomizedFormFromRequestState, ICustomizedFormProps, ICustomizedFormStepProps, TCustomizedFormFieldType } from '../interfaces'
import { messages } from './messages'
import './styles.scss'

const Field: Record<
  TCustomizedFormFieldType,
  | typeof TextInput
  | typeof DatePicker
  | typeof Checkbox
  | typeof RegionSelect
  | typeof PolicyAgreement
  | typeof SearchCitiesWithSuggestions
  | typeof LoanPurposeSelect
  | typeof InfoText
> = {
  text: TextInput,
  datePicker: DatePicker,
  checkbox: Checkbox,
  policyAgreement: PolicyAgreement,
  regionDropdown: RegionSelect,
  searchCitiesWithSuggestions: SearchCitiesWithSuggestions,
  loanPurposeSelect: LoanPurposeSelect,
  infoText: InfoText,
}

const loanAmountValidate = (state: string) => (value: string) => Number(value) === Number(state) ? 'incorrectValue' : undefined

const CustomizedFormStep: React.FC<ICustomizedFormStepProps & ICustomizedFormFromRequestState> = props => {
  const {
    fields,
    title,
    titleId,
    submitButton,
    errorMessageId,
    requestState,
    buttons,
    showPreviousForm,
    pageNumber,
    backButton,
    totalPagesQuantity,
    shouldShowRequiringFieldsLabel = true,
    hasValidationErrors,
    validating,
    tooltipText,
    tooltipTextId,
    shouldDisableButton,
    ignoreValidationForSubmitButton,
    requestStatusMeta,
    isMobile,
    calcButton,
  } = props

  const { isLoading, isFailure } = requestState
  const [loanValue, setLoanValue] = useState('')
  const { values } = useFormState()
  const { loanAmount } = values
  useEffect(() => {
    setLoanValue(loanAmount?.value)
  }, [loanAmount])

  const isSubmitButtonDisabled = shouldDisableButton || (ignoreValidationForSubmitButton ? false : hasValidationErrors || validating)

  const submitFormButton = (
    <Button
      type="submit"
      modifiers={['full-width', 'green']}
      className="space-holder-top16"
      loaderProps={{ isVisible: isLoading }}
      isDisabled={isSubmitButtonDisabled}
      {...submitButton}
      animatedContainerClass={shouldDisableButton ? '' : submitButton?.animatedContainerClass}
    />
  )

  return (
    <section className="customized-form">
      {(titleId || title) && <h3 className="space-holder24 customized-form__title">{title ?? <FormattedMessage id={titleId} />}</h3>}
      {fields.map(({ fieldType = 'text', ...field }) => {
        const Component = Field[fieldType]

        const fieldProps =
          isFailure && field.shouldBeTouchedBeforeError
            ? {
                ...field,
                shouldBeTouchedBeforeError: false,
                customValidator: loanAmountValidate(loanValue),
                errorText: requestStatusMeta,
              }
            : field

        //@ts-ignore
        return <Component key={fieldProps.name} {...fieldProps} className={classNames('space-holder8', fieldProps.className)} />
      })}
      {shouldShowRequiringFieldsLabel && (
        <p className="customized-form__requiring-fields-sign text_super-small-from-sm color_pale-black space-holder-top16">
          {messages.required}
        </p>
      )}
      <ErrorMessage
        isVisible={isFailure && Boolean(errorMessageId)}
        textId={requestStatusMeta || errorMessageId}
        className="customized-form__error text_center space-holder-top24"
      />
      {backButton && !isMobile && <Button {...backButton} onClick={backButton.onClick ? backButton.onClick : showPreviousForm} />}
      <div className="customized-form__actions">
        {calcButton && <Button {...calcButton} />}
        {(tooltipTextId || tooltipText) && shouldDisableButton ? (
          <BubblingTooltip messageId={tooltipTextId} content={tooltipText}>
            <span className={classNames('customized-form__submit-button-wrapper', submitButton?.animatedContainerClass)}>
              {submitFormButton}
            </span>
          </BubblingTooltip>
        ) : (
          submitFormButton
        )}
      </div>
      {backButton && isMobile && <Button {...backButton} onClick={backButton.onClick ? backButton.onClick : showPreviousForm} />}
      {buttons?.map((buttonProps, index) => (
        <Button
          className="space-holder-top8"
          key={`${buttonProps.textId}_${index}`}
          loaderProps={{ isVisible: isLoading }}
          {...buttonProps}
        />
      ))}
      {typeof pageNumber === 'number' && typeof totalPagesQuantity === 'number' && totalPagesQuantity >= 2 && (
        <PaginationDots dotsQuantity={totalPagesQuantity} activeDotNumber={pageNumber + 1} className="space-holder-top32" />
      )}
    </section>
  )
}

const mapState = (requestStatusName?: string) => (state: RootState) => ({
  requestState: getRequestState(requestStatusName)(state),
  requestStatusMeta: getRequestMeta(requestStatusName!)(state),
})

function CustomizedForm<FormValues>(props: ICustomizedFormProps<FormValues>): ReactElement {
  const {
    steps,
    onSubmit,
    initialValues,
    requestStatusName,
    tooltipTextId,
    tooltipText,
    shouldDisableButton,
    ignoreValidationForSubmitButton,
    autoFocus,
    forceScrollToTop,
  } = props

  const { requestState, requestStatusMeta } = useSelector(mapState(requestStatusName))
  const dispatch = useDispatch()
  const requestMessage = typeof requestStatusMeta === 'object' ? '' : requestStatusMeta

  const clearRequestState = () => {
    if (requestStatusName) dispatch(setInitialStatus({ name: requestStatusName }))
  }
  useMount(clearRequestState, clearRequestState)

  return (
    <WizardForm<FormValues> onSubmit={onSubmit} initialValues={initialValues} autoFocus={autoFocus} forceScrollToTop={forceScrollToTop}>
      {steps.map((stepProps, index) => (
        <WizardFormPage<ICustomizedFormStepProps & ICustomizedFormFromRequestState, FormValues>
          {...stepProps}
          key={`${stepProps.title ?? stepProps.titleId}_${index}`}
          Component={CustomizedFormStep}
          requestState={requestState}
          requestStatusMeta={requestMessage}
          tooltipTextId={tooltipTextId}
          tooltipText={tooltipText}
          shouldDisableButton={shouldDisableButton}
          ignoreValidationForSubmitButton={ignoreValidationForSubmitButton}
        />
      ))}
    </WizardForm>
  )
}

export default CustomizedForm
