import React, { useMemo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { CustomLeasingRadioLabel } from '@/components/composed/forms/CustomizedForms/CustomizedLeasingForm/components'
import { ECustomLeasingRadioOrder } from '@/components/composed/forms/CustomizedForms/CustomizedLeasingForm/enums'
import { BubblingTooltip, Button } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { EDirectoryRegions } from '@/components/ui/fields/RegionSelect/constants'
import SearchCitiesWithSuggestions from '@/components/ui/fields/SearchCitiesWithSuggestionsRHF'
import { RadioButton, RegionSelect, TextInput } from '@/components/ui/ReactHookFormFields'
import { FORM_ANCHOR } from '@/constants/financing'
import { useData } from '@/logic/data'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { EFinancingOfferType, EFinancingProductId } from '@/types'
import { ELegalDocumentType, TLegalDocuments } from '@/types/legalDocuments'
import { formatLocalizedString } from '@/utils/formatUtils'
import { asyncScrollIntoView } from '@/utils/helpers'
import { useMount } from '@/utils/hooks'
import { scrollToTop } from '@/utils/scroll'
import { ICustomizedFormFromRequestState, ICustomizedLeasingWizardFormProps } from '../../interfaces'
import { messages } from './messages'
import './styles.scss'

export const CustomizedLeasingFormFields = (props: ICustomizedLeasingWizardFormProps & ICustomizedFormFromRequestState) => {
  const { requestState, tooltipText, shouldDisableButton, form } = props
  const { isAuthenticated } = useAuthenticateUser()

  const { isLoading } = requestState

  const [productId, bankTitle, lessor, iconUrl] = form.watch(['productId', 'bankTitle', 'lessor', 'iconUrl'])

  const isSberLeasing = productId === EFinancingProductId.SBER_LEASING && lessor === EFinancingOfferType.SBER_LEASING
  const regionField = useMemo(() => {
    if (isSberLeasing) {
      return (
        <div className="customizedLeasingFormFieldLocation">
          <RegionSelect
            control={form.control}
            className="customizedLeasingFormFieldLocation"
            name="regionCode"
            modifiers={['light-grey']}
            directoryRegions={EDirectoryRegions.SBER}
            isRequired
            rules={{
              required: props.regionSelectErrorMessage ?? messages.error.requiredCity,
            }}
          />
        </div>
      )
    }

    return (
      <SearchCitiesWithSuggestions
        control={form.control}
        className="customizedLeasingFormFieldLocation"
        valueFormattingVariant="all"
        isRequiredWithoutSign
        isRequired={false}
        apiType="dadata"
        name="location"
      />
    )
  }, [isSberLeasing, form.control, props.regionSelectErrorMessage])

  const { data: documents = [] } = useData<TLegalDocuments>('legalDocuments')

  useMount(() => {
    if (location.hash === FORM_ANCHOR) {
      asyncScrollIntoView('#financingOfferForm')
    } else {
      scrollToTop()
    }
  })

  const submitFormButton = (
    <Button
      type="submit"
      modifiers={['green', 'full-width']}
      className="financing-leasing-offer-form-container__advance-button"
      loaderProps={{ isVisible: isLoading }}
      isDisabled={shouldDisableButton}
      animatedContainerClass={shouldDisableButton ? '' : 'financing-leasing-offer-form-button-animated-container'}
    >
      {messages.submitButton}
    </Button>
  )

  return (
    <section className="customizedLeasingFormFields">
      <div className="customizedLeasingFormFieldRadioGroup">
        <RadioButton
          control={form.control}
          value={productId}
          name="lessor"
          className={classNames('customizedLeasingFormFieldRadioGroup__radioItem', {
            customizedLeasingFormFieldRadioGroup__radioItem_checked: lessor === productId,
          })}
          CustomLabel={<CustomLeasingRadioLabel iconUrl={iconUrl} title={bankTitle} order={ECustomLeasingRadioOrder.FIRST} />}
        />
        <RadioButton
          control={form.control}
          value={'ALL'}
          name="lessor"
          className={classNames('customizedLeasingFormFieldRadioGroup__radioItem', 'customizedLeasingFormFieldRadioGroup__radioItem--all', {
            customizedLeasingFormFieldRadioGroup__radioItem_checked: lessor === 'ALL',
          })}
          CustomLabel={<CustomLeasingRadioLabel order={ECustomLeasingRadioOrder.SECOND} />}
        />
      </div>
      <h4 className="customizedLeasingFormFieldContactsTitle">{messages.leasing.contacts.title}</h4>
      <TextInput
        control={form.control}
        className="customizedLeasingFormFieldPhone"
        rules={{
          required: messages.error.requiredPhone,
        }}
        name="phone"
        type="tel"
        isReadonly={isAuthenticated}
        inputMode="numeric"
      />
      <TextInput
        control={form.control}
        className="customizedLeasingFormFieldEmail"
        rules={{
          required: messages.error.requiredEmail,
        }}
        name="email"
        type="email"
        label={messages.label.email}
      />
      <TextInput
        control={form.control}
        className="customizedLeasingFormFieldComment"
        rules={{
          required: messages.error.requiredEquipmentDetails,
        }}
        name="comment"
        type="textArea"
        label={messages.label.comment}
      />
      {regionField}
      <div className="customizedLeasingFormFieldAgreement text_small">
        {formatLocalizedString(messages.leasing.agreement.text, {
          LinkToAgreement: chunks => (
            <CommonLink
              key={ELegalDocumentType.registrationPolicy}
              shouldOpenInNewWindow
              url={getLegalDocumentUrl(documents, ELegalDocumentType.registrationRules)}
              className="customizedLeasingFormFieldAgreement__link"
            >
              {chunks}
            </CommonLink>
          ),
        })}
      </div>
      {tooltipText && shouldDisableButton ? (
        <BubblingTooltip content={tooltipText}>
          <span className={classNames('customizedLeasingFormFieldSubmitButton', 'financing-leasing-offer-form-button-animated-container')}>
            {submitFormButton}
          </span>
        </BubblingTooltip>
      ) : (
        <span className="customizedLeasingFormFieldSubmitButton">{submitFormButton}</span>
      )}
    </section>
  )
}
